.Button {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 0.938rem;
  font-weight: 500;
  text-transform: capitalize;
  min-width: 150px;
  padding: 0.625rem 0.938rem;
}