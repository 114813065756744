.PhoneInput {
  background: none;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  min-width: 500px;
  overflow: hidden;
  padding: 0.938rem 0.625rem;
  position: relative;
  text-align: center;
}
.focus {
  border-color: #1E8ADC;
}

.PhoneInput input {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.output {
  display: flex;
  justify-content: center;
}
