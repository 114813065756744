.Register {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Register img {
  width: 100px;
}

.Register h1 {
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0.625rem 0;
}

.message {
  font-size: 1.125rem;
  font-style: oblique;
  margin-bottom: 0.625rem;
  text-align: center;
  width: 480px;
}

.Register h2 {
  font-size: 1.375rem;
  font-weight: 500;
  margin: 0 0 0.625rem 0;
}

.Register form {
  margin-bottom: 1.875rem;
  min-width: 500px;
}

.Register form input {
  background: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.125rem;
  font-style: oblique;
  padding: 0.625rem 0.938rem;
}

.Register form div {
  display: flex;
}

.Register form div:first-child {
  margin-bottom: 0.625rem;
}

.Register form div:first-child input:first-child,
.Register form div:first-child input:last-child,
.Register form div:last-child input {
  flex-grow: 1;
}
.Register form div:first-child input:nth-child(2) {
  width: 40px;
  margin: 0 0.625rem;
}
.Register form div:last-child input:first-child {
  margin-right: 0.625rem;
}

.Register button:first-child {
  margin-right: 0.938rem;
}