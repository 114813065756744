.Digit {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 10px;
  min-height: 40px;
}

.Digit:nth-child(3), .Digit:nth-child(6) {
  margin-right: 30px;
}

.Digit div:first-child {
  font-size: 1.125rem;
  font-style: oblique;
  margin-bottom: 0.313rem;
}

.Digit div:last-child {
  background: #fff;
  height: 3px;
  width: 30px;
}