.App {
  align-items: center;
  background: #101010;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
}

.wrapper {
  background: #191919;
  border: 1px solid #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 45%;
  min-width: 33.33%;
  padding: 2rem 4rem;
  position: relative;
}
