.Alert {
  align-items: center;
  background: #EB5177;
  border-radius: 5px;
  color: #fff;
  display: flex;
  margin-bottom: 0.938rem;
  padding: 0.625rem 0.938rem;
}

.Alert h1 {
  font-size: 1.125rem;
  margin: 0 0.625rem 0 0;
}

.Alert div {
  font-size: 1.125rem;
}